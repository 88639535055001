import axios, { AxiosResponse } from "axios";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import * as ce from "./custom_errors";

/* eslint-disable-next-line no-undef */
const apiUrl = process.env.API_URL;
/* eslint-disable-next-line no-undef */
const llmUrl = process.env.LLM_URL;

export const serverName = "Standard Server (Hetzner)";

export class Models {
  private static instance: Models;
  // gemma2:27b-instruct-q3_K_S
  private llmDefaultModel: string = "phi3:14b-medium-128k-instruct-q6_K"; // default model
  private llmModels: Promise<string[]>;
  private llmModel: string = "";

  private constructor() {
    this.llmModels = getLlmModels();
  }

  public static getInstance(): Models {
    if (!Models.instance) {
      Models.instance = new Models();
    }
    return Models.instance;
  }

  public async getLlmModel(): Promise<string> {
    const llmModels = await this.llmModels;
    if (this.llmModel === "") {
      if (llmModels.includes(this.llmDefaultModel)) {
        this.llmModel = this.llmDefaultModel;
      } else {
        this.llmModel = llmModels[0];
      }
    }
    return this.llmModel;
  }
}

export function getModels(): Models {
  return Models.getInstance();
}

export class Workflows {
  private static instance: Workflows;
  private workflows: Promise<Workflow[]>;

  private constructor() {
    this.workflows = getLlmWorkflows();
  }

  public static getInstance(): Workflows {
    if (!Workflows.instance) {
      Workflows.instance = new Workflows();
    }
    return Workflows.instance;
  }

  public async getWorkflows(): Promise<Workflow[]> {
    return this.workflows;
  }
}

export function getWorkflows(): Workflows {
  return Workflows.getInstance();
}

/**
 * This function logs in the user.
 *
 * @param username The jassper username
 * @param password The jassper password
 * @returns {Promise<void>} A promise that resolves when the login request is sent
 */
export async function login(username: string, password: string): Promise<void> {
  try {
    localStorage.removeItem("authToken");
    const response: AxiosResponse = await axios.post(`${apiUrl}/authenticate`, {
      username: username,
      password: password,
    });

    if (response.status === 200 || response.status === 201) {
      console.log("Login successful! Setting auth-token.");
      localStorage.setItem("authToken", response.data);
      return Promise.resolve();
    } else {
      console.error("Login failed! Status code: " + response.status);
      return Promise.reject(new Error("Login failed! Status code: " + response.status));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return Promise.reject(error.response);
      } else if (error.request) {
        return Promise.reject(new Error("Error trying to fetch auth-token: No response received"));
      }
    }
    return Promise.reject(new Error("Unexpected error trying to fetch auth-token"));
  }
}

export async function isAuthTokenValid(): Promise<boolean> {
  try {
    console.log("Checking if auth token is valid.");
    const response: AxiosResponse = await axios.get(`${apiUrl}/user/me`);
    return 200 <= response.status && response.status <= 201;
  } catch (error) {
    console.log("Auth token is invalid.");
    return false;
  }
}

/**
 * This function logs out the user and redirects to the login page.
 *
 * @returns {void}
 */
export function logout(): void {
  localStorage.removeItem("authToken");
  localStorage.setItem("previousPage", window.location.href);
  window.location.href = "login.html";
}

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      if (localStorage.getItem("authToken")) {
        console.log("Token expired. Logging out.");
        localStorage.removeItem("authToken");
        document.location.href = "login.html";
      }
    }
    return Promise.reject(error);
  }
);

// #############################################################################
// ############################# LLM API FUNCTIONS #############################
// #############################################################################

export interface documentFile {
  name: string;
  originFileObj: File;
}
export interface V2CreateResponse {
  case_id: string;
  index_id: string;
  text: string;
  isPDF?: boolean;
}

export async function v2createChatWithDocumentFile(
  file: documentFile,
  model: string | null = null
): Promise<V2CreateResponse> {
  try {
    model ??= await getModels().getLlmModel();
    const requestUrl = `${llmUrl}/v2/chat/document`;

    const formData = new FormData();
    formData.append("file", file.originFileObj, file.name);
    formData.append("model", model);

    const response = await axios.post(requestUrl, formData);

    return response.data as V2CreateResponse;
  } catch (error) {
    console.error("Error trying to create chat with document:", error);
    throw new ce.CaseCreationError("Could not create chat with document. Please try again later.");
  }
}

export async function v2StartWorkflowFile(file: documentFile, workflowId: string): Promise<V2CreateResponse> {
  try {
    const requestUrl = `${llmUrl}/v2/workflow/${workflowId}`;

    const formData = new FormData();
    formData.append("file", file.originFileObj, file.name);

    const response = await axios.post(requestUrl, formData);

    return response.data as V2CreateResponse;
  } catch (error) {
    console.error("Error trying to start workflow with file:", error);
    throw new ce.CaseCreationError("Could not start workflow with file. Please try again later.");
  }
}

export type LLMV2Type = "chat" | "rag" | "playbook";
export interface LLMStreamV2body {
  prompt: string;
  model: string;
  index_id?: string; // needed for rag and playbook
  type: LLMV2Type; // 'rag', 'playbook' or 'chat'
}
export interface v2CaseCreatedResponse {
  content: string;
  type: string;
}
export interface LLMStreamV2Params {
  endpointDomain: string;
  endpointPath: string;
  model: string;
  llmStreamV2body?: LLMStreamV2body;
  llmStreamV2formData?: FormData;
  otherData?: any;
  cancelStream?: () => void;
  onQuestion?: (data: IOMainLLMMessage) => void;
  onAnswer?: (data: IOMainLLMMessage) => void;
  onFollowupQuestion?: (data: IOMainLLMMessage) => void;
  onFollowupAnswer?: (data: IOMainLLMMessage) => void;
  onDocumentQuestion?: (data: IOMainLLMMessage) => void;
  onDocumentAnswer?: (data: IOMainLLMMessage) => void;
  onPlaybookQuestion?: (data: IOMainLLMMessage) => void;
  onPlaybookAnswer?: (data: IOMainLLMMessage) => void;
  onSummaryQuestion?: (data: IOMainLLMMessage) => void;
  onSummaryAnswer?: (data: IOMainLLMMessage) => void;
  onStatus?: (value: string) => void;
  onAppend?: (value: string) => void;
  onStart?: () => void;
  onError?: () => void;
  onClose?: () => void;
  onSources?: (value: IMessageData) => void;
  onCaseCreated?: (data: v2CaseCreatedResponse) => void;
  onIndexCreated?: (data: v2CaseCreatedResponse) => void;
}

export const startLLMStreamV2 = async (llmStreamV2Params: LLMStreamV2Params): Promise<void> => {
  // TODO: timeout ?

  llmStreamV2Params.otherData ??= {};
  const requestData = !!llmStreamV2Params.llmStreamV2body
    ? JSON.stringify(llmStreamV2Params.llmStreamV2body)
    : !!llmStreamV2Params.llmStreamV2formData
    ? llmStreamV2Params.llmStreamV2formData
    : JSON.stringify(llmStreamV2Params.otherData);

  const llmRequestUrl = `${llmStreamV2Params.endpointDomain}/v2/${llmStreamV2Params.endpointPath}`;
  await fetchEventSource(llmRequestUrl, {
    method: "POST",
    openWhenHidden: true,
    body: requestData,
    headers: {
      ...(llmStreamV2Params.llmStreamV2body || requestData === JSON.stringify(llmStreamV2Params.otherData)
        ? { "Content-Type": "application/json" }
        : {}),
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      Accept: "text/event-stream",
    },
    onopen: async (res: Response): Promise<void> => {
      if (res.ok && res.status === 200) {
        llmStreamV2Params.onStart?.();
      } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        console.error("Client-Side Errror opening LLM Stream", res);
        llmStreamV2Params.onError?.();
      }
    },
    onmessage(event: { data: string }) {
      if (!event.data || event.data.length == 0) {
        return;
      }
      const data: IMessageData = JSON.parse(event.data);
      const additionalData = {
        server: serverName,
        model: llmStreamV2Params.model, // what to do in case of workflow?
        timestamp: Date.now(),
      };

      switch (data.type) {
        case "user_chat":
          llmStreamV2Params.onQuestion?.({
            ...data,
            category: "chat",
            role: "user",
            ...additionalData,
          });
          break;
        case "assistant_chat":
          llmStreamV2Params.onAnswer?.({
            ...data,
            category: "chat",
            role: "assistant",
            ...additionalData,
          });
          break;
        case "user_document":
          llmStreamV2Params.onDocumentQuestion?.({
            ...data,
            category: "document",
            role: "user",
            ...additionalData,
          });
          break;
        case "assistant_document":
          llmStreamV2Params.onDocumentAnswer?.({
            ...data,
            category: "document",
            role: "assistant",
            ...additionalData,
          });
          break;
        case "user_followup":
          llmStreamV2Params.onFollowupQuestion?.({
            ...data,
            category: "followup",
            role: "user",
            ...additionalData,
          });
          break;
        case "assistant_followup":
          llmStreamV2Params.onFollowupAnswer?.({
            ...data,
            category: "followup",
            role: "assistant",
            ...additionalData,
          });
          break;
        case "user_playbook":
          llmStreamV2Params.onPlaybookQuestion?.({
            ...data,
            category: "playbook",
            role: "user",
            ...additionalData,
          });
          break;
        case "assistant_playbook":
          llmStreamV2Params.onPlaybookAnswer?.({
            ...data,
            category: "playbook",
            role: "assistant",
            ...additionalData,
          });
          break;
        case "user_summary":
          llmStreamV2Params.onSummaryQuestion?.({
            ...data,
            category: "summary",
            role: "user",
            ...additionalData,
          });
          break;
        case "assistant_summary":
          llmStreamV2Params.onSummaryAnswer?.({
            ...data,
            category: "summary",
            role: "assistant",
            ...additionalData,
          });
          break;

        case "assistant_status":
          llmStreamV2Params.onStatus?.(data.content);
          break;

        case "sources":
          llmStreamV2Params.onSources?.(data);
          break;
        case "heartbeat":
          break;
        case "case_created":
          llmStreamV2Params.onCaseCreated?.(data as v2CaseCreatedResponse);
          break;
        case "index_created":
          llmStreamV2Params.onIndexCreated?.(data as v2CaseCreatedResponse);
          break;
        default:
          llmStreamV2Params.onAppend?.(data.content);
          break;
      }
    },
    onclose() {
      llmStreamV2Params.onClose?.();
    },
    onerror(err: any) {
      console.log("On error", err);
      llmStreamV2Params.onError?.();
      throw new Error();
    },
  });
};

// ########################### NOT FINISHED ####################################
interface CreatePlaybookDTO {
  title: string;
  index_location: string;
  description: string;
}
export const addNewPlaybook = async (payload: CreatePlaybookDTO) => {
  try {
    const response = await axios.post(`${apiUrl}/llm/playbook`, payload);
    return Promise.resolve(response.data);
  } catch (err) {
    return await Promise.reject();
  }
};

interface PlaybookDocument {
  playbook_id: string;
  document_id: string;
}
export const addDocumentToPlaybook = async (payload: PlaybookDocument) => {
  try {
    const response = await axios.post(`${apiUrl}/llm/playbook/document`, payload);

    return Promise.resolve(response.data);
  } catch (err) {
    return await Promise.reject();
  }
};

// #############################################################################
// ############################# OTHER API FUNCTIONS ###########################
// #############################################################################

interface UserMe {
  id: number;
  tenant: number;
  username: string;
  role: string;
  lastLogin: number;
  tenantProfile: {
    id: number;
    color: string; // maybe use for theme settings?
    enabledFeatures: string;
  };
}
async function getMe(): Promise<UserMe> {
  try {
    const response: AxiosResponse = await axios.get(`${apiUrl}/user/me`);
    return response.data as UserMe;
  } catch (error) {
    console.error("Error trying to get user:", error);
    throw new ce.UserRetrievalError(
      "Benutzerinformationen konnten nicht abgerufen werden. Bitte versuchen Sie es später erneut."
    );
  }
}

async function getLLMUrls(): Promise<{ [key: string]: string }> {
  try {
    const me: UserMe = await getMe();

    const urlPattern = /llm.(\w+):([a-zA-Z0-9.-]+)/g;
    const urls: { [key: string]: string } = {};

    let match;
    while ((match = urlPattern.exec(me.tenantProfile.enabledFeatures)) !== null) {
      const key = match[1];
      const url = match[2];
      urls[key] = url;
    }

    return urls;
  } catch (error) {
    console.error("Error trying to get llm-url:", error);
    throw new Error("Could not retrieve llm-url. Please try again later.");
  }
}

export const getDefaultWorkflows = (): {
  [key in string]: string; // ? DEFAULT_WORKFLOWS
} => {
  if (process.env.NODE_ENV === "production") {
    return {
      anonymize: "043b44d9-d5e7-4561-bd4e-fbe25c9f5c2c",
      summary: "8b218651-d465-4ca6-a80d-ddcd639b1682",
      summary_100: "3de11722-225a-443e-9f78-a326cc37a1e6",
      summary_300: "76e0493f-cbcd-4e9f-9cfc-13d6e138cf0a",
      summary_500: "409622ac-7cd5-4209-9635-6413e49c009f",
      summary_email: "7eebb336-a6e4-466c-9848-0b7bf1bb4722",
    };
  }

  return {
    anonymize: "22b0f329-eea4-4632-b807-dc8fddf79b98",
    summary: "30ce991e-9fa8-4635-b934-e82c66e13f0c",
    summary_100: "40598bbe-5499-4ebf-bc59-ec5077dcd8b3",
    summary_300: "523d0b6d-9763-4687-b4fa-f3f32d81545c",
    summary_500: "55969598-b13c-4278-84f7-2f1be03a7688",
    summary_email: "d672377c-ce21-4eff-981e-df7a396d7eef",
  };
};

type WorkflowRaw = {
  id: string;
  tenant: number;
  title: string;
  description: string;
  prompts: string;
};
export type Workflow = {
  id: string;
  tenant: number;
  title: string;
  description: string;
  prompts: {
    data: {
      model: string;
    };
  }[];
};

function getWorkflowModel(workflow: Workflow): string {
  try {
    return workflow.prompts[0]["data"]["model"];
  } catch (error) {
    console.error("Error trying to get workflow model:", error);
    throw new Error("Workflow-Modell konnte nicht abgerufen werden. Bitte versuchen Sie es später erneut.");
  }
}

async function getServerForWorkflow(workflowId: string): Promise<string> {
  try {
    const workflows: Workflow[] = await getWorkflows().getWorkflows();
    const workflow = workflows.find((workflow) => workflow.id === workflowId);
    const workflowModel: string = getWorkflowModel(workflow!);
    return workflowModel.split("_")[0];
  } catch (error) {
    console.error("Error trying to get llm-url for workflow:", error);
    throw new Error("Dieser Workflow ist aktuell nicht verfügbar. Bitte versuchen Sie es später erneut.");
  }
}

export async function getLLMUrlForServer(server: string): Promise<string> {
  try {
    if (server === "hetzner") {
      server = "default";
    }
    const llmUrlDict = await getLLMUrls();
    if (llmUrlDict[server]) {
      return `https://${llmUrlDict[server]}`;
    } else {
      console.error(`Access to server ${server} denied.`);
      throw new Error(`Zugriff zum Server ${server} wurde abgelehnt.`);
    }
  } catch (error) {
    console.error("Error trying to get llm-url for workflow:", error);
    throw new Error("Dieser Workflow ist aktuell nicht verfügbar. Bitte versuchen Sie es später erneut.");
  }
}

export async function getLLMUrlForWorkflow(workflowId: string): Promise<string> {
  try {
    const server = await getServerForWorkflow(workflowId);
    return getLLMUrlForServer(server);
  } catch (error) {
    console.error("Error trying to get llm-url for workflow:", error);
    throw new Error("Dieser Workflow ist aktuell nicht verfügbar. Bitte versuchen Sie es später erneut.");
  }
}

export interface LlmCase {
  messages: LlmCaseMessage[];
}
export interface IMessageBase {
  content: string;
  temperature?: number;
  topic?: string;
  system_prompt?: string;
}
export interface IMessageMeta extends IMessageBase {
  model: string;
  timestamp: number;
}
export interface LlmCaseMessage extends IMessageBase {
  timestamp: number;
  role: LLMStreamEventType;
  model: string;
  topic: string;
  temperature: number;
  case_id: string;
  index_id: string;
  index_references: IndexReference[];
  system_prompt: string;
}
export interface IndexReference {
  id: string;
  score: number;
}
/**
 * This function retrieves a llm-case.
 *
 * @param caseId The id of the case
 *
 * @returns {Promise<JSON>} A promise with the case data as JSON
 */
export async function getLlmCase(caseId: string): Promise<LlmCase> {
  try {
    const response: AxiosResponse = await axios.get(`${apiUrl}/case/${caseId}`);
    return response.data as LlmCase;
  } catch (error) {
    console.error("Error trying to get llm-case:", error);
    throw new ce.CaseRetrievalError("Could not retrieve case. Please try again later.");
  }
}

export interface IDocstoreData {
  [key: string]: {
    __data__: JSON;
  };
}
export async function getIndexData(indexId: string): Promise<IDocstoreData> {
  try {
    const response: AxiosResponse = await axios.get(`${llmUrl}/v1/index/${indexId}`);
    return response.data["docstore/data"];
  } catch (error) {
    console.error("Error trying to get index:", error);
    throw new ce.IndexRetrievalError("Could not retrieve index. Please try again later.");
  }
}

export function getUniqueIndexIds(messages: LlmCaseMessage[]): string[] {
  let uniqueIndexIds = Array.from(new Set(messages.map((message) => message.index_id)));
  uniqueIndexIds = uniqueIndexIds.filter((indexId) => indexId !== "");
  return uniqueIndexIds;
}

async function getLlmModels(): Promise<string[]> {
  try {
    const response: AxiosResponse = await axios.get(`${llmUrl}/v1/models`);
    return response.data["models"] as string[];
  } catch (error) {
    console.error("Error trying to get llm-models:", error);
    throw new ce.LlmModelRetrievalError("Could not retrieve models. Please try again later.");
  }
}

async function getLlmWorkflows(): Promise<Workflow[]> {
  try {
    const response: AxiosResponse = await axios.get(`${apiUrl}/llm/workflow`, {
      timeout: 15000,
    });
    const workflows: WorkflowRaw[] = response.data as WorkflowRaw[];
    return workflows.map((workflow) => {
      return {
        id: workflow.id,
        tenant: workflow.tenant,
        title: workflow.title,
        description: workflow.description,
        prompts: JSON.parse(workflow.prompts),
      };
    });
  } catch (error: any) {
    if (error.code === "ECONNABORTED") {
      console.error("Timeout trying to get workflows");
    } else {
      console.error("Error trying to get workflows:", error);
    }
    return Promise.reject(error);
  }
}

export type ServerType = "premium" | "standard" | "hetzner";
export interface LLMPlaybook {
  id: string;
  title: string;
  owner: string;
  tenant: string;
  index_location: ServerType;
  creation_date: number;
  documents: JSON[]; // TODO: define document type
}
export async function getLlmPlaybooks(): Promise<LLMPlaybook[]> {
  try {
    const response: AxiosResponse = await axios.get(`${apiUrl}/llm/playbook`, {
      timeout: 15000,
    });
    return response.data as LLMPlaybook[];
  } catch (error: any) {
    if (error.code === "ECONNABORTED") {
      console.error("Timeout trying to get playbooks");
    } else {
      console.error("Error trying to get playbooks:", error);
    }
    return Promise.reject(error);
  }
}

// #############################################################################
// ############################# LLM V1 API FUNCTIONS ##########################
// #############################################################################

export interface RAGResponse {
  sources: RAGSource[];
  response: string[];
}
export interface RAGSource {
  name: string;
  text: string;
  score: number;
}

export type LLMStreamEventCategory = "chat" | "document" | "playbook" | "followup" | "summary";
export type LLMStreamEventRole = "user" | "assistant";
export type LLMStreamEventType =
  | "user_chat"
  | "assistant_chat"
  | "user_document"
  | "assistant_document"
  | "user_playbook"
  | "assistant_playbook"
  | "user_followup"
  | "assistant_followup"
  | "sources"
  | "assistant_status"
  | "user_summary"
  | "assistant_summary"
  | "heartbeat"
  | "case_created"
  | "index_created";
export function isUserRole(type: LLMStreamEventType | string): boolean {
  if (type.startsWith("user_")) {
    return true;
  } else {
    return false;
  }
}

export const LLMStreamEventLabel: { [key in LLMStreamEventType]: string } = {
  user_chat: "Frage", //"Q",
  assistant_chat: "Antwort",
  user_document: "Frage", //"Q",
  assistant_document: "Antwort",
  user_playbook: "Abgleich mit Playbook Prompt", //"Abgleich mit Playbook Prompt",
  assistant_playbook: "Abgleich mit Playbook", //"Abgleich mit Playbook",
  user_followup: "Follow-up Prompt", //"Follow-up Prompt",
  assistant_followup: "Follow-up Antwort", //" Follow-up Antwort",
  sources: "",
  assistant_status: "Status",
  user_summary: "Frage (Zusammenfassung)", //"Q",
  assistant_summary: "Zusammenfassung",
  heartbeat: "",
  case_created: "",
  index_created: "",
};
export const LLMTypeToRoleAndCategory: {
  [key in LLMStreamEventType]: {
    role: LLMStreamEventRole;
    category: LLMStreamEventCategory;
  };
} = {
  user_chat: { role: "user", category: "chat" },
  assistant_chat: { role: "assistant", category: "chat" },
  user_document: { role: "user", category: "document" },
  assistant_document: { role: "assistant", category: "document" },
  user_playbook: { role: "user", category: "playbook" },
  assistant_playbook: { role: "assistant", category: "playbook" },
  user_followup: { role: "user", category: "followup" },
  assistant_followup: { role: "assistant", category: "followup" },
  user_summary: { role: "user", category: "summary" },
  assistant_summary: { role: "assistant", category: "summary" },
  assistant_status: { role: "assistant", category: "chat" },
  sources: { role: "assistant", category: "chat" },
  heartbeat: { role: "assistant", category: "chat" },
  case_created: { role: "assistant", category: "chat" },
  index_created: { role: "assistant", category: "chat" },
};
export const LLMMessageCategoryToDisplayType: { [key: string]: string } = {
  chat: "Chat",
  document: "Chat mit Dokument",
  playbook: "Chat mit Playbook",
  followup: "Chat mit Playbook",
  summary: "Zusammenfassung",
};

export type LLMStreamType =
  | "chat/completions"
  | "rag"
  | "workflow"
  | "workflow/remote"
  | "workflow/legacy"
  | "rag/summary";
export interface IMessageData extends IMessageBase {
  type: LLMStreamEventType;
}
export interface IMessageDataPlus extends IMessageData {
  category: LLMStreamEventCategory;
  role: LLMStreamEventRole;
}

export interface IOMainLLMMessage extends IMessageDataPlus {
  server: string;
  model: string;
  timestamp: number;
  retrieval_prompt?: string; // fix messy interfaces
  isHistoryLoading?: boolean;
  topicIsActive?: boolean;
}
