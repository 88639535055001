export class JaasperServiceError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "JaasperServiceError";
  }
}

export class UserRetrievalError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "UserRetrievalError";
  }
}

export class WorkflowLoadError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "WorkflowLoadError";
  }
}

export class EmailContentError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "EmailContentError";
  }
}

export class EmailTitleError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "EmailTitleError";
  }
}

export class CaseCreationTimeoutError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "CaseCreationTimeoutError";
  }
}

export class CaseCreationError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "CaseCreationError";
  }
}

export class CaseIdMissingError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "CaseIdMissingError";
  }
}

export class CaseRetrievalError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "CaseRetrievalError";
  }
}

export class IndexRetrievalError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "IndexRetrievalError";
  }
}

export class LlmModelRetrievalError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "LlmModelRetrievalError";
  }
}

export class IndexCreationTimeoutError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "IndexCreationTimeoutError";
  }
}

export class IndexCreationError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "IndexCreationError";
  }
}

export class RagPromptTimeoutError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "RagPromptTimeoutError";
  }
}

export class RagPromptError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "RagPromptError";
  }
}

export class LlmServerDownError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "LlmServerDownError";
  }
}
